<template>
  <div class="warp">
    <div class="main">
      <h3>企业码使用说明</h3>
      <p class="p1">1. 打开企业码让员工扫码后,系统会自动发送绑定申请。</p>
      <p class="p2">2. 企业负责人在微信进行审核操作。</p>
      <vue-qr class="vue-qr" :text="qrCodeUrl"
              :margin="0"
              colorDark="#333"
              colorLight="#fff"
              :logoScale="0.3"
              :size="200"></vue-qr>
      <p class="p3">{{comName}}</p>
    </div>
    <div class="btn">
      <van-button round block type="info" @click="goBackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { companyqrCode } from '@/api/notice.js'
import { Toast } from 'vant'
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr
  },
  data () {
    return {
      qrCodeUrl: '',
      comName: ''
    }
  },
  created() {
    this.companyqrCode()
  },
  methods: {
    companyqrCode() {
      companyqrCode().then(res => {
        if (res.data.code === 200) {
          this.qrCodeUrl = res.data.data.url
          this.comName = res.data.data.comName
        }
      })
    },
    goBackClick() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  .main {
    text-align: center;
    padding: 15px;
    h3 {
      margin-bottom: 5px;
    }
    p {
      font-weight: 600;
      line-height: 25px;
    }
    .p1, .p2 {
      text-align: left;
      font-size: 14px;
    }
    .p2 {
      margin-bottom: 30px;
    }
    .p3 {
      margin-top: 30px;
    }
  }
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
::v-deep .van-button--round {
  border-radius: 3px;
}
</style>
